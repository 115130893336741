import { Injectable } from '@angular/core';
import moment from 'moment';
import { ConfigProfile } from '../enums/config-profile.enum';
import { InvoiceModel, InvoiceResolvableStatus, InvoiceStatus } from '../models/api/invoice.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private appConfigService: AppConfigService) {}
  createInvoice(
    id: string,
    address: string,
    cryptoUri: string,
    product: { name: string; description: string },
    status: string,
    merchantAmount: { amount: string; currency: string },
    customerAmount: { amount: string; currency: string },
    rate: {
      currencyFrom: string;
      currencyTo: string;
      value: string;
    },
  ): InvoiceModel {
    return {
      id: id,
      address: address,
      activeSince: 0,
      cryptoUri: cryptoUri,
      createdAt: 0,
      timeoutTime: 0,
      reference: '',
      returnUrl: '',
      layer2Attributes: null,
      product: product,
      status: InvoiceStatus.ACTIVE,
      preparedSince: 0,
      rate: rate,
      cryptoTransactions: [],
      merchantAmount: merchantAmount,
      customerAmount: customerAmount,
      networkFee: '0',
      settlementAmount: {
        amount: '',
        currency: '',
      },
      paid: {
        amount: '',
        currency: '',
        diff: '',
        amountUnconfirmed: '',
      },
      confirmingSince: 0,
      paidSince: 0,
      expiredSince: 0,
      errorSince: 0,
      confirmations: 0,
      requiredConfirmations: 0,
      requiredConfirmationsToRefund: 0,
      unhandledExceptions: false,
      flags: {
        resolvableStatus: InvoiceResolvableStatus.YES,
        overpaymentResolvable: false,
      },
      refunds: [],
      settlement: null,
      merchantEmail: '',
      merchantId: null,
      url: '',
      referenceValue: {
        amount: '',
        currency: '',
      },
      notifyUrl: '',
      notifyEmail: '',
      refundLink: '',
      takeInfo: {
        taken: false,
        originalSettlementAmount: '',
        settlementCurrency: '',
      },
      buttonInvoiceId: '',
      subscriptionId: '',
      merchantBusinessInfo: null,
      cryptoExternalUrl: null,
      fee: null,
      invoiceComment: null,
      overUnderPaidAmount: null,
      paymentModule: '',
      customerEmail: null,
      customerPaymentMethodId: this.getCustomerPaymentMethodId(),
      resolvedManually: false,
    };
  }

  createDemoInvoice(): InvoiceModel {
    const demoInvoice = this.createInvoice(
      'inv123456',
      '3J98t1WpEZ73CNmQviecrnyiWrnqRhWNLy',
      '3J98t1WpEZ73CNmQviecrnyiWrnqRhWNLy',
      { name: 'Product name', description: 'Product description' },
      'active',
      { amount: '100', currency: 'EUR' },
      { amount: '0.00569306', currency: 'BTC' },
      { currencyFrom: 'EUR', currencyTo: 'BTC', value: '17565.2461067' },
    );

    demoInvoice.paid.diff = '0.00569306';
    demoInvoice.paid.amountUnconfirmed = '0';

    let now = moment();
    demoInvoice.activeSince = now.unix();
    demoInvoice.timeoutTime = now.add(15, 'm').unix();

    return demoInvoice;
  }

  getCustomerPaymentMethodId(): string {
    switch (this.appConfigService.config.profile) {
      case ConfigProfile.PROD:
      case ConfigProfile.UAT:
      case ConfigProfile.FAT:
        return 'BITCOIN-BLOCKCHAIN-MAINNET-BTC-CURRENCY';
      default:
        return 'BITCOIN-BLOCKCHAIN-SIGNET-BTC-CURRENCY';
    }
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import {
  getCryptoWalletsStatisticsAction,
  getCryptoWalletsStatisticsErrorAction,
  getCryptoWalletsStatisticsSuccessAction,
} from './crypto-wallets-statistics.actions';

export const ADDRESSES_ENDPOINT = '/admin/crypto-wallet-statistics';

@Injectable()
export class CryptoWalletsStatisticsEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCryptoWalletsStatisticsAction),
      mergeMap(() =>
        this.api.get(ADDRESSES_ENDPOINT).pipe(
          map((walletsStatistics) => getCryptoWalletsStatisticsSuccessAction({ walletsStatistics })),
          catchError((errors) => of(getCryptoWalletsStatisticsErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}

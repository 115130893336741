/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { MerchantProfileType } from '../enums/merchant-profile-type.enum';
import { ErrorModel } from '../models/api/error.model';
import { MerchantBusinessInfoModel } from '../models/api/merchant-business-info.model';
import { MerchantProfileUnionModel } from '../models/api/merchant-profile/merchant-profile-union.model';

export enum MerchantActionTypes {
  SaveBusinessInfo = 'merchant/POST_BUSINESS_INFO_REQUEST',
  SaveBusinessInfoSuccess = 'merchant/POST_BUSINESS_INFO_SUCCESS',
  SaveBusinessInfoError = 'merchant/POST_BUSINESS_INFO_ERROR',

  StartMerchantVerificationAdmin = 'merchant/START_MERCHANT_VERIFICATION_ADMIN_REQUEST',
  StartMerchantVerificationAdminSuccess = 'merchant/START_MERCHANT_VERIFICATION_ADMIN_SUCCESS',
  StartMerchantVerificationAdminError = 'merchant/START_MERCHANT_VERIFICATION_ADMIN_ERROR',

  UpdateMerchantProfileAdmin = 'merchant/UPDATE_MERCHANT_PROFILE_ADMIN_REQUEST',
  UpdateMerchantProfileAdminSuccess = 'merchant/UPDATE_MERCHANT_PROFILE_ADMIN_SUCCESS',
  UpdateMerchantProfileAdminError = 'merchant/UPDATE_MERCHANT_PROFILE_ADMIN_ERROR',

  getMerchantPopupForm = 'merchant/GET_MERCHANT_POPUP_FORM',
  getMerchantPopupFormSuccess = 'merchant/GET_MERCHANT_POPUP_FORM_SUCCESS',
  getMerchantPopupFormError = 'merchant/GET_MERCHANT_POPUP_FORM_ERROR',

  getMerchantFiatSettlementsEnabled = 'merchant/GET_MERCHANT_FIAT_SETTLEMENTS_ENABLED',
  getMerchantFiatSettlementsEnabledSuccess = 'merchant/GET_MERCHANT_FIAT_SETTLEMENTS_ENABLED_SUCCESS',
  getMerchantFiatSettlementsEnabledError = 'merchant/GET_MERCHANT_FIAT_SETTLEMENTS_ENABLED_ERROR',
}

export const saveBusinessInfoAction = createAction(
  MerchantActionTypes.SaveBusinessInfo,
  props<{ businessInfoModel: MerchantBusinessInfoModel }>(),
);

export const saveBusinessInfoSuccessAction = createAction(
  MerchantActionTypes.SaveBusinessInfoSuccess,
  props<{ businessInfoModel: MerchantBusinessInfoModel }>(),
);

export const saveBusinessInfoErrorAction = createAction(
  MerchantActionTypes.SaveBusinessInfoError,
  props<{ errors: ErrorModel[] }>(),
);

export const startMerchantVerificationAdminAction = createAction(
  MerchantActionTypes.StartMerchantVerificationAdmin,
  props<{ merchantHashId: string; merchantProfileType: MerchantProfileType }>(),
);

export const startMerchantVerificationAdminSuccessAction = createAction(
  MerchantActionTypes.StartMerchantVerificationAdminSuccess,
);

export const startMerchantVerificationAdminErrorAction = createAction(
  MerchantActionTypes.StartMerchantVerificationAdminError,
  props<{ errors: ErrorModel[] }>(),
);

export const updateMerchantProfileAdminAction = createAction(
  MerchantActionTypes.UpdateMerchantProfileAdmin,
  props<{ merchantProfile: MerchantProfileUnionModel }>(),
);

export const updateMerchantProfileAdminSuccessAction = createAction(
  MerchantActionTypes.UpdateMerchantProfileAdminSuccess,
  props<{ merchantProfile: MerchantProfileUnionModel }>(),
);

export const updateMerchantProfileAdminErrorAction = createAction(
  MerchantActionTypes.UpdateMerchantProfileAdminError,
  props<{ errors: ErrorModel[] }>(),
);

export const getMerchantPopupFormAction = createAction(
  MerchantActionTypes.getMerchantPopupForm,
  props<{ hashId: string }>(),
);

export const getMerchantPopupFormSuccessAction = createAction(
  MerchantActionTypes.getMerchantPopupFormSuccess,
  props<{ popupFormFilled: boolean }>(),
);

export const getMerchantPopupFormErrorAction = createAction(
  MerchantActionTypes.getMerchantPopupFormError,
  props<{ errors: ErrorModel[] }>(),
);

export const getMerchantFiatSettlementsEnabledAction = createAction(
  MerchantActionTypes.getMerchantFiatSettlementsEnabled,
  props<{ hashId: string }>(),
);

export const getMerchantFiatSettlementsEnabledSuccessAction = createAction(
  MerchantActionTypes.getMerchantFiatSettlementsEnabledSuccess,
  props<{ merchantFiatSettlementsEnabled: boolean }>(),
);

export const getMerchantFiatSettlementsEnabledErrorAction = createAction(
  MerchantActionTypes.getMerchantFiatSettlementsEnabledError,
  props<{ errors: ErrorModel[] }>(),
);

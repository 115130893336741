/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { MerchantModel } from '../models/api/merchant.model';

export enum merchantDetailActions {
  GET_REQUEST = 'merchantDetail/GET_REQUEST',
  GET_SUCCESS = 'merchantDetail/GET_SUCCESS',
  GET_ERROR = 'merchantDetail/GET_ERROR',

  UPDATE_MERCHANT_DETAIL_FIAT_SETTLEMENTS_ENABLED_ACTION = 'merchantDetail/UPDATE_MERCHANT_DETAIL_FIAT_SETTLEMENTS_ENABLED',
  UPDATE_MERCHANT_DETAIL_FIAT_SETTLEMENTS_ENABLED_SUCCESS_ACTION = 'merchantDetail/UPDATE_MERCHANT_DETAIL_FIAT_SETTLEMENTS_ENABLED_SUCCESS',
  UPDATE_MERCHANT_DETAIL_FIAT_SETTLEMENTS_ENABLED_ERROR_ACTION = 'merchantDetail/UPDATE_MERCHANT_DETAIL_FIAT_SETTLEMENTS_ENABLED_ERROR',
}

export const getMerchantDetailAction = createAction(merchantDetailActions.GET_REQUEST, props<{ merchantId: string }>());

export const getMerchantDetailSuccessAction = createAction(
  merchantDetailActions.GET_SUCCESS,
  props<{ merchant: MerchantModel }>(),
);

export const getMerchantDetailErrorAction = createAction(
  merchantDetailActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

export const updateMerchantDetailFiatSettlementsEnabledAction = createAction(
  merchantDetailActions.UPDATE_MERCHANT_DETAIL_FIAT_SETTLEMENTS_ENABLED_ACTION,
  props<{ merchantHashId: string; value: boolean }>(),
);

export const updateMerchantDetailFiatSettlementsEnabledDetailSuccessAction = createAction(
  merchantDetailActions.UPDATE_MERCHANT_DETAIL_FIAT_SETTLEMENTS_ENABLED_SUCCESS_ACTION,
  props<{ value: boolean }>(),
);

export const updateMerchantDetailFiatSettlementsEnabledDetailErrorAction = createAction(
  merchantDetailActions.UPDATE_MERCHANT_DETAIL_FIAT_SETTLEMENTS_ENABLED_ERROR_ACTION,
  props<{ errors: ErrorModel[] }>(),
);

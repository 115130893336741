import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import {
  userRegistrationAction,
  userRegistrationErrorAction,
  userRegistrationSuccessAction,
} from './user-registration.actions';

const USER_REGISTRATION_ENDPOINT = '/user/register';

@Injectable()
export class UserRegistrationEffect {
  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationAction),
      switchMap(({ userRegistration }) =>
        this.api.post(USER_REGISTRATION_ENDPOINT, userRegistration).pipe(
          map(() => userRegistrationSuccessAction()),
          catchError((errors) => observableOf(userRegistrationErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}

import { createReducer, on } from '@ngrx/store';
import { CryptoWalletStatisticsTo } from '../../models/api/crypto-wallets-statistics.model';
import { StateModel } from '../../models/auxiliary/state.model';
import {
  getCryptoWalletsStatisticsErrorAction,
  getCryptoWalletsStatisticsSuccessAction,
} from './crypto-wallets-statistics.actions';

export type CryptoWalletsStatisticsState = StateModel<CryptoWalletStatisticsTo[]>;

const INITIAL_STATE: CryptoWalletsStatisticsState = {
  errors: null,
  data: [],
};

export const cryptoWalletsStatisticsReducer = createReducer(
  INITIAL_STATE,

  on(getCryptoWalletsStatisticsSuccessAction, (state, { walletsStatistics }) => ({
    errors: null,
    data: walletsStatistics,
  })),
  on(getCryptoWalletsStatisticsErrorAction, (state, { errors }) => ({
    ...state,
    errors: errors,
  })),
);

import { MerchantProfileType } from '../../enums/merchant-profile-type.enum';
import { QueryParams } from '../../utils';
import { CryptoExternalUrlModel } from './crypto-external-url.model';
import { RefundTo } from './refund.model';
import { SettlementInfoModel } from './settlement-info.model';
import { TransactionModel } from './transaction.model';

export enum InvoiceResolvableStatus {
  YES = 'YES',
  NO = 'NO',
  WAITING = 'WAITING',
}

export enum InvoiceResolvableSubStatus {
  OVERPAID_NOT_ENOUGH_TO_COVER_NETWORK_FEE = 'OVERPAID_NOT_ENOUGH_TO_COVER_NETWORK_FEE',
  UNDERPAID_NOT_ENOUGH_TO_COVER_NETWORK_FEE = 'UNDERPAID_NOT_ENOUGH_TO_COVER_NETWORK_FEE',
  PAYMENT_DETECTED_AFTER_EXPIRATION = 'PAYMENT_DETECTED_AFTER_EXPIRATION',
}

export enum NotRefundableCause {
  REFUND_AMOUNT_ZERO = 'REFUND_AMOUNT_ZERO',
  REFUND_AMOUNT_TOO_LOW = 'REFUND_AMOUNT_TOO_LOW',
  REFUND_AMOUNT_NOT_ENOUGH_TO_COVER_NETWORK_FEE = 'REFUND_AMOUNT_NOT_ENOUGH_TO_COVER_NETWORK_FEE',
  WRONG_INVOICE_STATE = 'WRONG_INVOICE_STATE',
  NOT_ALLOWED = 'NOT_ALLOWED',
  LEGACY_INVOICE_WITHOUT_NETWORK_FEE = 'LEGACY_INVOICE_WITHOUT_NETWORK_FEE',
}

export enum Layer2ProtocolName {
  LIGHTNING = 'LIGHTNING',
}

export enum InvoiceStatus {
  PREPARED = 'prepared',
  ACTIVE = 'active',
  CONFIRMING = 'confirming',
  PAID = 'paid',
  EXPIRED = 'expired',
  ERROR = 'error',
}

export interface InvoiceModel {
  activeSince: number;
  address: string;
  cryptoUri: string;
  createdAt: number;
  emailInvoiceId?: string;
  timeoutTime: number;
  serverTime?: number; // present only in websocket
  id: string;
  reference: string;
  returnUrl: string;
  product: {
    name: string;
    description: string;
  };
  status: InvoiceStatus;
  preparedSince: number;
  rate: {
    currencyFrom: string;
    currencyTo: string;
    value: string;
  };
  cryptoTransactions: TransactionModel[];
  merchantAmount: {
    amount: string;
    currency: string;
  };
  customerAmount: {
    amount: string;
    currency: string;
  };
  networkFee: string;
  settlementAmount?: {
    amount: string;
    currency: string;
  };
  paid: {
    amount: string;
    currency: string;
    diff: string;
    amountUnconfirmed: string;
  };
  confirmingSince: number;
  paidSince: number;
  expiredSince: number;
  errorSince: number;
  confirmations: number;
  requiredConfirmations: number;
  requiredConfirmationsToRefund: number;
  unhandledExceptions: boolean;
  flags: {
    refundable?: true;
    notRefundableCause?: NotRefundableCause;
    resolvableStatus: InvoiceResolvableStatus;
    resolvableSubStatus?: InvoiceResolvableSubStatus;
    overpaymentResolvable: boolean;
  };
  refunds: RefundTo[];
  settlement: SettlementInfoModel;
  merchantEmail: string;
  merchantId: string;
  url: string;
  referenceValue: {
    amount: string;
    currency: string;
  };
  notifyUrl: string;
  notifyEmail: string;
  refundLink: string;
  takeInfo: {
    taken: boolean;
    originalSettlementAmount: string;
    settlementCurrency: string;
  };
  buttonInvoiceId: string;
  subscriptionId: string | null;
  merchantBusinessInfo: {
    merchantProfileType: MerchantProfileType;

    firstOrCompanyName: string;
    surnameOrCompanyId: string;
    street: string;
    city: string;
    zip: string;
    country: string;

    website: string;
    phone: string;
    email: string;

    logo: string;
  };
  layer2Attributes: Layer2Attributes;
  cryptoExternalUrl: CryptoExternalUrlModel;
  fee: string;
  invoiceComment: string;
  overUnderPaidAmount: string;
  paymentModule: string;
  customerEmail: string;
  customerPaymentMethodId?: string;
  resolvedManually: boolean;
  resolvedByEmail?: string;
  fireblocksVaultAccount?: FireblocksVaultAccount;
  closedAt?: number;
}

export interface FireblocksVaultAccount {
  id: string;
  name: string;
}

export interface Layer2Attributes {
  protocolName: Layer2ProtocolName;
}

export interface LightningAttributes extends Layer2Attributes {
  bolt: string;
  paymentHash: string;
}

export interface LightningLnurlAttributes extends LightningAttributes {
  lnurl: string;
}

export interface InvoiceCreationModel {
  invoice: {
    amount: string;
    currencyFrom: string;
    currencyTo: string;
  };
  settlement: {
    currency: string;
  };
  reference: string;
  returnUrl: string;
  notifyUrl: string;
  notifyEmail: string;
  product: {
    name: string;
    description: string;
  };
}

export interface ManualTransactionModel {
  invoiceId: string;
  body: {
    amount: string;
    txid: string;
    timestamp: number;
  };
}

export interface InvoiceStatusRequest {
  status: string;
  comment: string;
}

export interface SelectedPaymentMethodTo {
  paymentMethodId: string;
}

export interface ButtonInvoice extends QueryParams {
  productName: string;
  productDescription: string;
  reference: string;
  returnUrl: string;
  merchantAmount: string;
  merchantCurrency: string;
  customerCurrency: string;
  customerInfo: string;
}

import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  getMerchantDetailAction,
  getMerchantDetailErrorAction,
  getMerchantDetailSuccessAction,
  updateMerchantDetailFiatSettlementsEnabledAction,
  updateMerchantDetailFiatSettlementsEnabledDetailErrorAction,
  updateMerchantDetailFiatSettlementsEnabledDetailSuccessAction,
} from '../actions/merchant-detail.actions';
import { ApiService } from '../services/api.service';
import { MessageService } from '../services/message.service';
import { MERCHANT_ENDPOINT } from './merchant-page.effect';

@Injectable()
export class MerchantDetailEffect {
  private readonly actions$ = inject(Actions);
  private readonly apiService = inject(ApiService);
  private readonly messageService = inject(MessageService);

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantDetailAction),
      mergeMap(({ merchantId }) =>
        this.apiService.get(`${MERCHANT_ENDPOINT}/${merchantId}`).pipe(
          map((merchant) => getMerchantDetailSuccessAction({ merchant })),
          catchError((errors) => observableOf(getMerchantDetailErrorAction(errors))),
        ),
      ),
    ),
  );

  updateFiatSettlementsEnabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMerchantDetailFiatSettlementsEnabledAction),
      switchMap(({ merchantHashId, value }) =>
        this.apiService.patch(`/merchants/${merchantHashId}/fiat-settlements-enabled`, value).pipe(
          map(() => updateMerchantDetailFiatSettlementsEnabledDetailSuccessAction({ value })),
          catchError((errors) => observableOf(updateMerchantDetailFiatSettlementsEnabledDetailErrorAction(errors))),
        ),
      ),
    ),
  );

  showSuccessMessageOnUpdateFiatSettlementsEnabled$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateMerchantDetailFiatSettlementsEnabledDetailSuccessAction),
        tap(({ value }) => {
          const resultText = value ? 'enabled' : 'disabled';
          this.messageService.success(`FIAT settlements were successfully ${resultText}`);
        }),
      ),
    { dispatch: false },
  );
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, Observable, combineLatest, interval, of as observableOf } from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeMap, scan, startWith, switchMap } from 'rxjs/operators';
import {
  getMerchantVerificationInfoAction,
  getMerchantVerificationInfoErrorAction,
  getMerchantVerificationInfoSuccessAction,
  refreshMerchantVerificationInfoOnIntervalSubscribeAction,
  refreshMerchantVerificationInfoOnIntervalUnsubscribeAction,
} from '../actions/merchant-verification-info.actions';
import { ApiService } from '../services/api.service';

const MERCHANT_VERIFICATION_INFO_ENDPOINT = '/merchant/verification-info';

@Injectable()
export class MerchantVerificationInfoEffect {
  getMerchantVerificationInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantVerificationInfoAction),
      mergeMap(() =>
        this.api.get(MERCHANT_VERIFICATION_INFO_ENDPOINT).pipe(
          map((verificationInfo) => getMerchantVerificationInfoSuccessAction({ verificationInfo })),
          catchError((errors) => observableOf(getMerchantVerificationInfoErrorAction(errors))),
        ),
      ),
    ),
  );

  /**
   * Track start and stop actions to update store every 5s
   */
  refreshMerchantVerificationInfoOnInterval$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(
        ofType(refreshMerchantVerificationInfoOnIntervalSubscribeAction),
        scan((acc) => ++acc, 0),
        startWith(0),
      ),
      this.actions$.pipe(
        ofType(refreshMerchantVerificationInfoOnIntervalUnsubscribeAction),
        scan((acc) => ++acc, 0),
        startWith(0),
      ),
    ]).pipe(
      // map to true/false if should be subscribed
      map(([subs, unsubs]) => subs - unsubs > 0),
      distinctUntilChanged(),
      switchMap((isSubscribed): Observable<Action> => {
        if (isSubscribed) {
          return interval(5000).pipe(
            mergeMap(() =>
              this.api.get(MERCHANT_VERIFICATION_INFO_ENDPOINT).pipe(
                map((verificationInfo) => getMerchantVerificationInfoSuccessAction({ verificationInfo })),
                catchError((errors) => observableOf(getMerchantVerificationInfoErrorAction(errors))),
              ),
            ),
          );
        }
        return EMPTY;
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}

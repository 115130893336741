/* eslint-disable max-classes-per-file */
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../models/api/error.model';
import { MerchantModel } from '../models/api/merchant.model';
import { PageRequestModel } from '../models/auxiliary/page-request.model';
import { PagingModel } from '../models/auxiliary/paging.model';

export enum merchantPageActions {
  GET_REQUEST = 'merchantPage/GET_REQUEST',
  GET_SUCCESS = 'merchantPage/GET_SUCCESS',
  GET_ERROR = 'merchantPage/GET_ERROR',
}

export const getMerchantPageAction = createAction(
  merchantPageActions.GET_REQUEST,
  props<{ paging: PageRequestModel }>(),
);

export const getMerchantPageSuccessAction = createAction(
  merchantPageActions.GET_SUCCESS,
  props<{ merchants: PagingModel<MerchantModel> }>(),
);

export const getMerchantPageErrorAction = createAction(
  merchantPageActions.GET_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

import { createReducer, on } from '@ngrx/store';
import {
  getMerchantDetailAction,
  getMerchantDetailErrorAction,
  getMerchantDetailSuccessAction,
  updateMerchantDetailFiatSettlementsEnabledDetailSuccessAction,
} from '../actions/merchant-detail.actions';
import { MerchantModel } from '../models/api/merchant.model';
import { StateModel } from '../models/auxiliary/state.model';

export type MerchantDetailState = StateModel<MerchantModel>;

const INITIAL_STATE: MerchantDetailState = {
  errors: null,
  data: null,
};

export const merchantDetailReducer = createReducer(
  INITIAL_STATE,
  on(getMerchantDetailAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getMerchantDetailSuccessAction, (state, { merchant }) => ({
    errors: null,
    data: merchant,
  })),
  on(getMerchantDetailErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
  on(updateMerchantDetailFiatSettlementsEnabledDetailSuccessAction, (state, { value }) => ({
    errors: null,
    data: { ...state.data, fiatSettlementsEnabled: value },
  })),
);

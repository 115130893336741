import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getLimitsAndFeesAction,
  getLimitsAndFeesErrorAction,
  getLimitsAndFeesSuccessAction,
  updateLimitsAndFeesAction,
  updateLimitsAndFeesErrorAction,
  updateLimitsAndFeesSuccessAction,
} from '../actions/limits-and-fees.actions';
import { ADMIN_ENDPOINT_PREFIX } from '../constants';
import { ApiService } from '../services/api.service';

export const LIMITS_AND_FEES_ENDPOINT = `/${ADMIN_ENDPOINT_PREFIX}/merchants/fees`;

@Injectable()
export class LimitsAndFeesEffect {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLimitsAndFeesAction),
      mergeMap((action) =>
        this.api.get(LIMITS_AND_FEES_ENDPOINT).pipe(
          map((limitsAndFees) => getLimitsAndFeesSuccessAction({ limitsAndFees })),
          catchError((errors) => observableOf(getLimitsAndFeesErrorAction(errors))),
        ),
      ),
    ),
  );

  updateSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLimitsAndFeesAction),
      mergeMap(({ limitsAndFees }) =>
        this.api.put(LIMITS_AND_FEES_ENDPOINT, limitsAndFees).pipe(
          map((limitsAndFees) => updateLimitsAndFeesSuccessAction({ limitsAndFees })),
          catchError((errors) => observableOf(updateLimitsAndFeesErrorAction(errors))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}

import { Injectable, inject } from '@angular/core';
import { GOOGLE_ANALYTICS_DEVTEST_ID, GOOGLE_ANALYTICS_PROD_ID } from '../../config/constants';
import { AppConfigService } from '../app-config.service';
import { GA4CustomEventName, GA4PublicInvoiceCustomEventParams } from './ga4-custom-event.model';

import ControlParams = Gtag.ControlParams;
import EventParams = Gtag.EventParams;
import CustomParams = Gtag.CustomParams;
import EventNames = Gtag.EventNames;

type GtagEventParams = ControlParams | EventParams | CustomParams;

@Injectable({
  providedIn: 'root',
})
export class GA4Service {
  private readonly appConfigService = inject(AppConfigService);

  private gtagExists(): boolean {
    // also, we should always check if gtag exists to avoid potential runtime errors (GA4 script should be provided in index.html)
    return typeof window !== 'undefined' && typeof gtag !== 'undefined';
  }

  setConfig(): void {
    if (this.gtagExists()) {
      if (this.appConfigService.config.profile === 'prod') {
        gtag('config', GOOGLE_ANALYTICS_PROD_ID);
      } else {
        gtag('config', GOOGLE_ANALYTICS_DEVTEST_ID, { debug_mode: true });
      }
    }
  }

  trackPublicInvoiceEvent(name: GA4CustomEventName, params: GA4PublicInvoiceCustomEventParams): void {
    this.trackEvent(name, params);
  }

  trackEvent(name: EventNames | GA4CustomEventName, params?: GtagEventParams): void {
    if (this.gtagExists()) {
      gtag('event', name, params);
    }
  }

  trackPageViewEvent(params: GtagEventParams): void {
    if (this.gtagExists()) {
      gtag('event', 'page_view', params);
    }
  }
}

import { createReducer, on } from '@ngrx/store';
import { StateModel } from '../../../models/auxiliary/state.model';
import {
  getSubscriptionEnabledInfoAction,
  getSubscriptionEnabledInfoErrorAction,
  getSubscriptionEnabledInfoSuccessAction,
} from './subscription-enabled-info.actions';

export type SubscriptionEnabledInfoState = StateModel<boolean>;

const INITIAL_STATE: SubscriptionEnabledInfoState = {
  errors: null,
  data: null,
  initial: true,
};

export const subscriptionEnabledInfoReducer = createReducer(
  INITIAL_STATE,
  on(getSubscriptionEnabledInfoAction, (state) => ({
    ...INITIAL_STATE,
  })),
  on(getSubscriptionEnabledInfoSuccessAction, (state, { isSubscriptionEnabled }) => ({
    errors: null,
    data: isSubscriptionEnabled,
  })),
  on(getSubscriptionEnabledInfoErrorAction, (state, { errors }) => ({
    errors: errors,
  })),
);

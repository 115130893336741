import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { CaptureConsole } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { AbstractComponent } from './shared/components/abstract.component';
import { ConfigProfile } from './shared/enums/config-profile.enum';
import { GA4Service } from './shared/services/analytics/ga4.service';
import { AppConfigService } from './shared/services/app-config.service';
import { LocaleService } from './shared/services/locale.service';
import { SeoService } from './shared/services/seo.service';

@Component({
  selector: 'bp-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AbstractComponent implements OnDestroy {
  constructor(
    public router: Router,
    appConfigService: AppConfigService,
    // need to call constructor of LocaleService to load locales
    localeService: LocaleService,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    private route: ActivatedRoute,
    private ga4: GA4Service,
  ) {
    super();
    // Google Analytics tracking
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.ga4.setConfig();
      }
    });
    /**
     * Show or don't show cookies based on environments.
     */
    const cookieyesScript = document.createElement('script');
    cookieyesScript.id = 'cookieyes';
    cookieyesScript.type = 'text/javascript';

    switch (appConfigService.config.profile) {
      case ConfigProfile.PROD:
        // production cookieyes account
        cookieyesScript.src = 'https://cdn-cookieyes.com/client_data/6cbc0b3180778f13a8b41cff/script.js';
        document.head.appendChild(cookieyesScript);
        break;
      case ConfigProfile.UAT:
        // uat - test cookieyes account
        cookieyesScript.src = 'https://cdn-cookieyes.com/client_data/d297b280b83e26454e203274/script.js';
        document.head.appendChild(cookieyesScript);
        break;
      default:
        // on local, dev, test we don't use CookieYes
        break;
    }

    Sentry.init({
      dsn: 'https://cafada83a75048ab962bededed830f83@o877649.ingest.sentry.io/5828506',
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new Integrations.BrowserTracing({
          tracePropagationTargets: ['localhost', /(dev|test|fat|uat)-bpay\.cfmin\.eu/],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new CaptureConsole({
          levels: ['error'],
        }) as any,
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      environment: appConfigService.config.profile,
    });

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((data) => {
        // needed for smooth-scroll working on landing pages
        const body = document.getElementsByTagName('body')[0];
        if (!this.router.url.includes('platform') && !this.router.url.includes('public')) {
          body.setAttribute('style', 'height: fit-content');
          // needed only for landing pages which have og:url defined
          this.seoService.updateCanonicalUrl(data['meta']['og:url']);
        } else {
          body.removeAttribute('style');
        }

        this.seoService.updateMetaTags(data['meta']);
      });

    /**
     * Automatically scrolls to the page fragment, if it is included in the url.
     * The way <a [routerLink]="<link>" [fragment]="<fragment>"></a>
     * doesn't work on landing pages because of delayed elements appearance when animations are turned on
     */
    this.route.fragment.pipe(takeUntil(this.unsubscribe$)).subscribe((fragment: string) => {
      if (fragment) {
        setTimeout(() => {
          const article = document.getElementById(fragment);
          if (article) {
            article.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
          }
        }, 100);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

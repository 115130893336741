import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/api/error.model';
import { UserRegistrationTo } from '../../models/api/user-registration.model';

enum UserRegistrationActionTypes {
  USER_REGISTRATION_REQUEST = 'signup/USER_REGISTRATION_REQUEST',
  USER_REGISTRATION_SUCCESS = 'signup/USER_REGISTRATION_SUCCESS',
  USER_REGISTRATION_ERROR = 'signup/USER_REGISTRATION_ERROR',
}

export const userRegistrationAction = createAction(
  UserRegistrationActionTypes.USER_REGISTRATION_REQUEST,
  props<{ userRegistration: UserRegistrationTo }>(),
);

export const userRegistrationSuccessAction = createAction(UserRegistrationActionTypes.USER_REGISTRATION_SUCCESS);

export const userRegistrationErrorAction = createAction(
  UserRegistrationActionTypes.USER_REGISTRATION_ERROR,
  props<{ errors: ErrorModel[] }>(),
);

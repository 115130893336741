import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { validate } from 'wallet-address-validator';
import { cryptoAddressVerifyRequestAction } from '../actions/crypto-address.actions';
import { CryptoAddressTo } from '../models/api/crypto-address.model';
import { AppStateModel } from '../models/auxiliary/app-state.model';
import { selectCryptoAddressVerify } from '../selectors/crypto-address-verify.selector';

@Injectable({
  providedIn: 'root',
})
export class CryptoAddressValidators {
  private ERROR_OBJECT = { invalidCryptoAddress: true };

  constructor() {}

  public settlementMethodCryptoAddressValidator(
    active: () => boolean,
    paymentMethodId: () => string,
    store: any,
  ): (control: UntypedFormGroup) => Observable<{ [key: string]: boolean } | null> {
    return (control: UntypedFormGroup) => {
      const addressControl: UntypedFormControl = control.get('settlementAccount').get('address') as UntypedFormControl;

      return this.cryptoAddressValidatorBackendRequest(addressControl, paymentMethodId(), control, store);
    };
  }

  public asyncCryptoAddressValidator(
    store: any,
    addressControlName: string,
    paymentMethodId: () => string,
  ): (control: AbstractControl) => Observable<{ [key: string]: boolean } | null> {
    return (control: AbstractControl) => {
      const addressControl: UntypedFormControl = control.get(addressControlName) as UntypedFormControl;

      return this.cryptoAddressValidatorBackendRequest(addressControl, paymentMethodId(), control, store);
    };
  }

  // TODO: only send request if address or currency changes
  private cryptoAddressValidatorBackendRequest(
    addressControl: UntypedFormControl,
    networkId: string,
    control: AbstractControl,
    store: Store<AppStateModel>,
  ): Observable<{ [key: string]: boolean } | null> {
    if (this.isEmptyOrInvalid(addressControl) || !networkId) {
      return of(null);
    }

    const cryptoAddress: CryptoAddressTo = { address: addressControl.value, networkId: networkId };
    store.dispatch(cryptoAddressVerifyRequestAction({ cryptoAddressModel: cryptoAddress }));

    /**
     *  setting form as invalid, so user can't continue without validation
     *  could have happened if we couldn't load data from store fast enough
     */
    control.setErrors(this.ERROR_OBJECT);
    return store.select(selectCryptoAddressVerify).pipe(
      map((state) => {
        if (state.data !== null) {
          if (state.data) {
            addressControl.setErrors(null);
            control.setErrors(null);
            return null;
          }
          addressControl.setErrors(this.ERROR_OBJECT);
          control.setErrors(this.ERROR_OBJECT);
          return this.ERROR_OBJECT;
        }
      }),
    );
  }

  public cryptoAddressValidator(currency: string): (control: UntypedFormControl) => { [key: string]: boolean } {
    return (control: UntypedFormControl) => {
      if (!currency || currency === '' || this.isEmptyOrInvalid(control)) {
        return null;
      }

      const address = control.value;
      if (address.substr(4) === 'xpub') {
        return this.ERROR_OBJECT;
      }

      try {
        return validate(address, currency) ? null : this.ERROR_OBJECT;
      } catch (e) {
        return null;
      }
    };
  }

  private isEmptyOrInvalid(control: UntypedFormControl): boolean {
    return !control || !control.value || control.value === '' || !control.valid;
  }
}

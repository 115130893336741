import { registerLocaleData } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocaleService {
  public locale: string;

  constructor(@Inject(LOCALE_ID) public localeId: string) {
    this.registerLocale(localeId);
  }

  private registerLocale(culture: string): void {
    if (!culture) {
      return;
    }
    this.locale = culture;
    const localeId = culture.substring(0, 2);

    // improvement to previous solution of a webpack bug (common/package.json#export wildcard usage)
    // https://github.com/angular/angular-cli/issues/22154#issuecomment-967061797
    // now it initialize only needed locales and not all of them (>1000 ~ 4mb)
    // https://github.com/angular/angular-cli/issues/22154#issuecomment-977253266
    // https://stackoverflow.com/questions/61035621/how-do-i-dynamically-import-locales-in-angular-9
    import(
      // constants.ts#PUBLIC_PAGE_LANGUAGES (codes)
      /* webpackInclude: /\b(en|cs|de|it)\.mjs$/ */
      /* webpackMode: "lazy-once" */
      /* webpackChunkName: "angular_common_locales_en_cs_de_it_mjs" */
      `/node_modules/@angular/common/locales/${localeId}.mjs`
    ).then((module) => {
      registerLocaleData(module.default);
    });
  }
}
